import { gsap, TweenMax, TimelineMax } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import * as ScrollMagic from "scrollmagic"
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"
// import * as indicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'

gsap.registerPlugin(CSSRulePlugin, CSSPlugin, ScrollToPlugin)
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

/**
 * scrollmagic
 *
 * для запуска функциональности добавить к nav.navbar
 * класс `navbar-sticky`. после прикрепления панели
 * добавляется класс `is-pinned`
 *
 */
export class StickyNavbar {
  selector = '.navbar-sticky'
  pinnedClass = 'is-pinned'
  zIndex = 39
  indicators = false
  menuOffset = 450
  navbar: HTMLElement|null
  scene: ScrollMagic.Scene|undefined

  constructor() {
    this.navbar = document.querySelector(this.selector)
    this.createController()
  }

  createController(): ScrollMagic.Controller|undefined {
    if (!this.navbar) return

    const controller = new ScrollMagic.Controller({})
    const menuSceneOffset = 450

    this.scene = new ScrollMagic.Scene({
      triggerElement: this.selector,
      triggerHook: 'onLeave',
      offset: this.menuOffset,
      duration: 0
    })
    .setClassToggle(this.selector, this.pinnedClass)
    .setPin(this.selector)
    .on('enter', () => {
      TweenMax.to(this.selector, 0.3, {
        duration: 0.3,
        y: menuSceneOffset - 2,
        zIndex: this.zIndex
      })
    })
    .on('leave', () => {
      TweenMax.to(this.selector, 0.2, {
        y: 0,
        zIndex: this.zIndex
      })
    })

    if (this.indicators) this.showIndicators()

    this.scene.addTo(controller)
    return controller
  }

  showIndicators(): void {
    if (this.scene?.addIndicators) {
      this.scene.addIndicators({
        name: 'STICKY',
        colorStart: 'green',
        colorEnd: 'red',
        colorTrigger: 'blue',
        indent: this.menuOffset
      })
    }
  }
}
